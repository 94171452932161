import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import axios from "axios";

import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import HomeIcon from '@mui/icons-material/Home';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SavingsIcon from '@mui/icons-material/Savings';
import SpeedIcon from '@mui/icons-material/Speed';
import Grid from '@mui/material/Grid';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import EvStationIcon from '@mui/icons-material/EvStation';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HvacIcon from '@mui/icons-material/Hvac';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterIcon from '@mui/icons-material/Water';
import InfoIcon from '@mui/icons-material/Info';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import { format } from "date-fns";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from './Loading'

import ReactECharts from 'echarts-for-react';
import Niro from "./assets/niro.png"
import mtec_wp from "./assets/mtec-wp.png"

registerLocale('de', de)

function formatNumber(number, decimals) {
  if(typeof( decimals) == "undefined"){
    decimals = 0;
  }
  if (number > 1000) {
    return (number / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 });
  } else {
    return parseFloat(number).toLocaleString(undefined, { maximumFractionDigits: decimals });
  }
}

function getWattLabel(number) {
  if (number < 0)
    number = number * (-1);
  if (number > 1000) {
    return "kW";
  } else {
    return "W";
  }
}

const toUtcDate = function (requestDate) {
  return new Date(Date.UTC(requestDate.getUTCFullYear(), requestDate.getUTCMonth(),
    requestDate.getUTCDate(), requestDate.getUTCHours(),
    requestDate.getUTCMinutes(), requestDate.getUTCSeconds()));
}

const hostBaseUrl = process.env.REACT_APP_API_URL;
const today = new Date();
today.setHours(0, 0, 0, 0);

export const DashboardComponent = () => {

  const [pvPowerLive, setPvPowerLive] = useState(0);
  const [pvPowerTotal, setPvPowerTotal] = useState(0);
  const [gridImportTotal, setGridImportTotal] = useState(0);
  const [gridExportTotal, setGridExportTotal] = useState(0);
  const [homePowerLive, setHomePowerLive] = useState(0);
  const [ventilationPowerLive, setVentilationPowerLive] = useState(0);
  const [washerPowerLive, setWasherPowerLive] = useState(0);
  const [serverPowerLive, setServerPowerLive] = useState(0);
  const [dryerPowerLive, setDryerPowerLive] = useState(0);
  const [freezerPowerLive, setFreezerPowerLive] = useState(0);
  const [fridgePowerLive, setFridgePowerLive] = useState(0);
  const [heatpumpPowerLive, setheatpumpPowerLive] = useState(0);
  const [gridPowerLive, setGridPowerLive] = useState(0);
  const [selfConsumptionTotal, setSelfConsumptionTotal] = useState(0);
  const [homePowerTotal, setHomePowerTotal] = useState(0);
  const [heatpumpPowerTotal, setheatpumpPowerTotal] = useState(0);
  const [powerConsumptionTotal, setPowerConsumptionTotal] = useState(0);
  const [chargePowerTotal, setChargePowerTotal] = useState(0);
  const [chargePowerTotalPVsupported, setChargePowerTotalPVsupported] = useState(0);
  const [chargePowerLive, setChargePowerLive] = useState(0);

  const [chartDataPV, setChartDataPV] = useState([0]);
  const [chartDataPVLabels, setChartDataPVLabels] = useState([0]);
  const [chartDataHomePower, setChartDataHomePower] = useState([0]);
  const [chartDataheatpumpPower, setChartDataheatpumpPower] = useState([0]);
  const [remainingChargeTime, setRemainingChargeTime] = useState([0]);
  const [chartDataChargePower, setChartDataChargePower] = useState([0]);
  //const [chartDataPowerConsumptionTotal, setChartDataPowerConsumptionTotal] = useState([0]);
  const [liveData, setLiveData] = useState();
  const [temperatureData, setTemperatureData] = useState();
  const liveData_Default = {
    "chargePower": 0,
    "charging": false,
    "connected": false,
    "gridPower": 0,
    "homePower": 0,
    "heatpumpPowerLive": 0,
    "ventilationPowerLive": 0,
    "washerPowerLive": 0,
    "serverPowerLive": 0,
    "dryerPowerLive": 0,
    "freezerPowerLive": 0,
    "fridgePowerLive": 0,
    "pvPower": 0,
    "vehicleRange": 0,
    "vehicleSoc": 0,
    "targetSoc": 100,
    "vehicleTargetSoc": 100,
    "vehicleTitle": "",
    "chargeMode": null
  };
  const temperatureData_Default = {
    "humidityRoom": 0,
    "tempRoom": 0,
    "outdoorTemp": 0,
    "waterTemp": 0,
    "operatingMode": "-",
    "switchValvePosition": "-",
    "controlMode": 0
  };

  const [chargeMode, setChargeMode] = useState();
  const [minCurrent, setMinCurrent] = useState();
  const [maxCurrent, setMaxCurrent] = useState();

  const [heatpumpInfoOpen, setHeatpumpInfoOpen] = useState();
  const [homePowerInfoOpen, setHomePowerInfoOpen] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const getChartOptions = () => { 
    //console.log("getChartOptions")
    return {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    tooltip: {
      //triggerOn: 'item',
      position: function (pos, params, dom, rect, size) {
        // tooltip will be fixed on the right if mouse hovering on the left,
        // and on the left if hovering on the right.
        //console.log(arguments);
        var obj = {top: -120};
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;



        dom.className = "tooltip";

        return obj;
      },
      formatter: (params) => {
        /*if(!params[0] || !params[0].value){
            return "";
        }*/

        let dateLocale = new Date(params[1]?.axisValue);
        let outputDate = dateLocale.toLocaleDateString() + " " + dateLocale.toLocaleTimeString();
        let output = `<b>${outputDate}</b><br/><br/>
        ${params[0]?.marker}${params[0]?.seriesName}:  <b>${(params[0]?.value / 1000).toFixed(3)} kW</b></br>
        ${params[1]?.marker.replace("background-color:black", "background-color:black")}${params[1]?.seriesName}:  <b>${(params[1]?.value/1000).toFixed(3)} kW</b></br>
        ${params[2]?.marker.replace("background-color:black", "background-color:red")}${params[2]?.seriesName}:  <b>${(params[2]?.value / 1000).toFixed(3)} kW</b></br>
        ${params[3]?.marker.replace("background-color:black", "background-color:green")}${params[3]?.seriesName}:  <b>${(params[3]?.value / 1000).toFixed(3)} kW</b>
        
        `;

        if(dateLocale.getTime() > new Date().getTime() || isNaN(dateLocale.getTime())){
          return null;
        }

//console.log(params);

        return (output);
      },
    },
    xAxis: {
      type: 'category',
      data: chartDataPVLabels,
      axisLabel: {
        formatter: (val) => { 
          let date = new Date(val);
          return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
        }
      },
      axisPointer: {
        //value: new Date().toUTCString(),
        snap: true,
        lineStyle: {
          color: '#1976d2',
          width: 2
        },
        label: {
          show: false,
          backgroundColor: '#7581BD'
        },
        handle: {
          show: true,
          color: '#1976d2'
        }
      }
    },
    grid: {
      top: 0,
      left: 25,
      right: 25,
      height: 420
    },
    dataZoom: [
      {
        type: 'inside',
        throttle: 200
      }
    ],
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (val) => { 
          return (val / 1000);
        }
      }
    },
    series: [
      {
        name: "Produziert",
        data: chartDataPV,
        type: 'line',
        smooth: true,
        areaStyle: {},
        itemStyle: {
          color: 'orange'
        },
      },
      {
        name: "Hausverbrauch",
        data: chartDataHomePower,
        type: 'line',
        smooth: true,
        stack: "homeTotal",
        //areaStyle: {},
        itemStyle: {
          color: 'black'
        },
      },
      {
        name: "Wärmepumpe",
        data: chartDataheatpumpPower,
        type: 'line',
        smooth: true,
        stack: "homeTotal",
        areaStyle: {
          color: "rgba(255, 0, 0, 0.7)"
        },
        itemStyle: {
          color: 'black'
        },
      },
      {
        name: "Auto geladen",
        data: chartDataChargePower,
        type: 'line',
        smooth: true,
        stack: "homeTotal",
        areaStyle: {
          color: "rgba(0, 255, 0, 0.7)"
        },
        itemStyle: {
          color: 'black'
        },
      }/*,
      {
        name: "Energieverbrauch (gesamt)",
        data: chartDataPowerConsumptionTotal,
        type: 'line',
        smooth: true,
        //stack: "homeTotal",
        //areaStyle: {},
        itemStyle: {
          color: 'blue'
        }
      }*/
    ]
  }};

  const queryChartData = async () => {
    let requestedDate = format(window.queryDay, "yyy-MM-dd");

    let noCache = "";
    if(requestedDate == format(today, "yyy-MM-dd")){
      noCache = "&noCache=true";
    }

    let token = '';
    if(process.env.REACT_APP_USE_AUTH == "true"){
      token = await getAccessTokenSilently();
    }

    // frische daten
    /*const result = await axios.get(`${hostBaseUrl}chartData?day=${requestedDate}${noCache}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });*/

    const result = await axios.get(`${hostBaseUrl}chartData?day=${requestedDate}${noCache}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    setChartDataPV(result.data.pvPower);
    setChartDataPVLabels(result.data.pvPowerLabels);
    setChartDataHomePower(result.data.homePower);
    setChartDataheatpumpPower(result.data.heatpumpPower);
    setChartDataChargePower(result.data.chargePower);
    //setChartDataPowerConsumptionTotal(result.data.powerConsumptionTotal);
  };

  const queryApi = async (reset) => {
    if (!window.queryDay) {
      window.queryDay = new Date();
    }

    let requestedDate = format(window.queryDay, "yyy-MM-dd");

    // reset view
    if (typeof reset != "undefined") {
      setPvPowerLive(0);
      setPvPowerTotal(0);
      setGridImportTotal(0);
      setGridExportTotal(0);
      setSelfConsumptionTotal(0);
      setPowerConsumptionTotal(0);
      setChargePowerTotal(0);
      setChargePowerTotalPVsupported(0);
      setChargePowerLive(0);
      setHomePowerLive(0);
      setVentilationPowerLive(0);
      setServerPowerLive(0);
      setDryerPowerLive(0);
      setFreezerPowerLive(0);
      setFridgePowerLive(0);
      setWasherPowerLive(0);
      setGridPowerLive(0);
      setHomePowerTotal(0);
      setLiveData(liveData_Default);  
      setTemperatureData(temperatureData_Default);  
      setChargeMode("pv");  
      setMinCurrent(0);
      setMaxCurrent(0);
      setheatpumpPowerLive(0);
      setheatpumpPowerTotal(0);
    }

    let token = '';
    if(process.env.REACT_APP_USE_AUTH == "true"){
      token = await getAccessTokenSilently();
    }

    // frische daten
    //
    const result = await axios.get(`${hostBaseUrl}stats?day=${requestedDate}&live=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })

    setPvPowerLive(result.data.live.pvPower);
    setPvPowerTotal(result.data.pvPowerTotal);
    setGridImportTotal(result.data.gridImportTotal);
    setGridExportTotal(result.data.gridExportTotal);
    setSelfConsumptionTotal(result.data.selfConsumptionTotal);
    setPowerConsumptionTotal(result.data.powerConsumptionTotal);
    setChargePowerTotal(result.data.chargePowerTotal);
    setChargePowerTotalPVsupported(result.data.chargePowerPVsupportedTotal);
    setChargePowerLive(result.data.live.chargePower);
    setHomePowerTotal(result.data.homePowerTotal);
    setHomePowerLive(result.data.live.homePower);
    setVentilationPowerLive(result.data.live.ventilationPower);
    setServerPowerLive(result.data.live.serverPower);
    setDryerPowerLive(result.data.live.dryerPower);
    setFreezerPowerLive(result.data.live.freezerPower);
    setFridgePowerLive(result.data.live.fridgePower);
    setWasherPowerLive(result.data.live.washerPower);
    setGridPowerLive(result.data.live.gridPower * (-1));
    setLiveData(result.data.live);
    setTemperatureData(result.data.temperature);
    setheatpumpPowerTotal(result.data.heatpumpPowerTotal);
    setheatpumpPowerLive(result.data.live.heatpumpPower);

    let remaimingCapacity = result.data.live.vehicleCapacity - (result.data.live.vehicleCapacity / result.data.live.targetSoc * result.data.live.vehicleSoc);
    let remaimingMinutes = 60 / result.data.live.chargePower * (remaimingCapacity * 1000 * 1.1); // x 1.1 = efficiency 90%

    let remainingHours = parseInt(remaimingMinutes/60);
    let remainingMinutesRest = parseInt(remaimingMinutes) - remainingHours * 60;

    setRemainingChargeTime(`${remainingHours}h ${remainingMinutesRest}min bis ${result.data.live.targetSoc}%`);

    let chargeModeVisible = result.data.live.chargeMode;

    if(chargeModeVisible == "minpv"){
      if(result.data.live.minCurrent == 9){
        chargeModeVisible = "minpv2";
      }else if(result.data.live.minCurrent == 14){
        chargeModeVisible = "minpv3";
      }
      else if(result.data.live.minCurrent == 16){
        chargeModeVisible = "minpv35";
      }
    }else if(chargeModeVisible == "now"){
      if(result.data.live.maxCurrent == 8){
        chargeModeVisible = "now5"; 
      }
      if(result.data.live.maxCurrent == 11){
        chargeModeVisible = "now7"; 
      }
      if(result.data.live.maxCurrent == 13){
        chargeModeVisible = "now8"; 
      }
      if(result.data.live.maxCurrent == 14){
        chargeModeVisible = "now9"; 
      }
    }
    setMinCurrent(result.data.live.minCurrent);
    setMaxCurrent(result.data.live.maxCurrent);
    setChargeMode(chargeModeVisible);    
  };

  const switchDay = (day) => {
    setStartDate(day);
    window.queryDay = toUtcDate(day);
  
    //reset/load
    queryApi(true);
    queryChartData();
  };
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    switchDay(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const calendarBackward = (e) => {
    let currentDate = new Date(window.queryDay);
    let newDate = new Date(currentDate.getTime() - 60 * 60 * 24 * 1000);
    switchDay(newDate);
  };

  const calendarForward = (e) => {
    let currentDate = new Date(window.queryDay);
    let newDate = new Date(currentDate.getTime() + 60 * 60 * 24 * 1000);
    switchDay(newDate);
  };

  const changeChargeMode = async (selectedOption) => {
    
    let newMode = selectedOption.target.value;
    setChargeMode(newMode);
    console.log(newMode);

    const token = await getAccessTokenSilently();

    axios.post(`${hostBaseUrl}chargeMode?mode=${newMode}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  };

  const globalDashboardClick = (event) => {
    if(event.target.tagName.toLocaleLowerCase() == "canvas"){
      return;
    }
   
    let tooltips = document.getElementsByClassName("tooltip");
    if(tooltips.length > 0){
      tooltips[0].style.display = "none";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await queryApi();
    };

    const fetchLiveChartData = async () => {
      await queryChartData();
    };

    const id = setInterval(() => {
      fetchData();
    }, 5000);

    fetchData();

    const idChartInterval = setInterval(() => {
      fetchLiveChartData();
    }, 120000);

    fetchLiveChartData();

    return () => { clearInterval(id); clearInterval(idChartInterval); };
  }, [])



  return (
    <Grid container spacing={0} padding={0} onClick={globalDashboardClick}>
      <Grid item xs={12}>
        <Typography component="p" variant="caption" style={{ textAlign: "center", background: "#ececec" }}>
          Jetzt
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><WbSunnyIcon style={{ color: 'orange', fontSize: "xxx-large" }} /></div>
        <Typography component="p" variant="h5" style={{ textAlign: "center" }}>
          {formatNumber(pvPowerLive)}<span style={{ fontSize: "medium" }}> {getWattLabel(pvPowerLive)}</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Produktion
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><HomeIcon onClick={() => setHomePowerInfoOpen(true)} style={{ cursor: 'pointer', color: 'chocolate', fontSize: "xxx-large" }} /></div>
        <Typography onClick={() => setHomePowerInfoOpen(true)} component="p" variant="h5" style={{ cursor: 'pointer', textAlign: "center" }}>
          {formatNumber(homePowerLive)}<span style={{ fontSize: "small" }}> {getWattLabel(homePowerLive)}</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ cursor: 'pointer', textAlign: "center" }} color={"darkslategray"}>
          <InfoIcon onClick={() => setHomePowerInfoOpen(true)} style={{ cursor:'pointer', color: 'gray', fontSize: "small" }} /> Hausverbrauch
        </Typography>


        <Dialog onClose={() => setHomePowerInfoOpen(false)} open={homePowerInfoOpen}>
          <List>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Wohnraumlüftung:</span> {formatNumber(ventilationPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(ventilationPowerLive)}</span></div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Kühlschrank:</span> {formatNumber(fridgePowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(fridgePowerLive)}</span></div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Tiefkühler:</span> {formatNumber(freezerPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(freezerPowerLive)}</span></div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Waschmaschine:</span> {formatNumber(washerPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(washerPowerLive)}</span></div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Trockner:</span> {formatNumber(dryerPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(dryerPowerLive)}</span></div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Nas/Server:</span> {formatNumber(serverPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(serverPowerLive)}</span></div>
            </ListItem>

            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Sonstiges:</span> {formatNumber(homePowerLive - serverPowerLive - dryerPowerLive - washerPowerLive - ventilationPowerLive - fridgePowerLive - freezerPowerLive)} <span style={{ fontSize: "small" }}> {getWattLabel(homePowerLive - serverPowerLive - dryerPowerLive - washerPowerLive - ventilationPowerLive - fridgePowerLive - freezerPowerLive)}</span></div>
            </ListItem>
          </List>
        </Dialog>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><PowerIcon style={{ color: (gridPowerLive > 0 ? "gray" : "red"), fontSize: "xxx-large" }} /></div>
        <Typography component="p" variant="h5" style={{ textAlign: "center" }}>
          {gridPowerLive > 0 ? formatNumber(gridPowerLive) : formatNumber(gridPowerLive * (-1))}<span style={{ fontSize: "small" }}> {getWattLabel(gridPowerLive)}</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          {gridPowerLive > 0 ? "Netzeinspeisung" : "Netzbezug"}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <div style={{ display: (pvPowerLive > 0) ? "" : "none" }} id="linehPowerproduction"></div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: (gridPowerLive > 0) ? "" : "none" }} id="linehPowerexport"></div>
        <div style={{ display: (gridPowerLive < 0) ? "" : "none" }} id="linehPowerconsumption"></div>
      </Grid>

      
      <Grid item xs={4} style={{ }} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><ThermostatIcon style={{ color: 'gray', fontSize: "x-large" }} /></div>
        <Typography component="div" variant="subtitle2" style={{ textAlign: "center" }}>
          Außen {formatNumber(temperatureData?.outdoorTemp, 1)}<span style={{ fontSize: "small" }}> °C</span>
          <br/>
          EG {formatNumber(temperatureData?.tempRoom, 1)}<span style={{ fontSize: "small" }}> °C</span> / {formatNumber(temperatureData?.humidityRoom)}<span style={{ fontSize: "small" }}> %</span>
          <br/>
          OG {formatNumber(temperatureData?.tempRoomOG, 1)}<span style={{ fontSize: "small" }}> °C</span> / {formatNumber(temperatureData?.humidityRoomOG)}<span style={{ fontSize: "small" }}> %</span>
          <br/>
          Puffer {formatNumber(temperatureData?.waterTemp, 1)}<span style={{ fontSize: "small" }}> °C</span>
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ }} >
          <div className={"sectionTitle"} style={{ textAlign: "center" }}>
              <img src={mtec_wp} style={{height: "80px"}}></img>
          </div>
          <Typography component="p" variant="h5" style={{ textAlign: "center" }}>
            {formatNumber(heatpumpPowerLive)}<span style={{ fontSize: "small" }}> {getWattLabel(heatpumpPowerLive)}</span>
          </Typography>
          <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
            Wärmepumpe
          </Typography>
        </Grid>

      <Grid item xs={4} style={{ }} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><InfoIcon onClick={() => setHeatpumpInfoOpen(true)} style={{ cursor:'pointer', color: 'gray', fontSize: "x-large" }} /></div>      
        <Typography component="div" variant="subtitle2" style={{ textAlign: "center" }} >
          <div style={{ fontSize: "small", "display": (temperatureData?.controlMode == "0") ? "" : "none" }}>Standby</div>
          <div style={{ fontSize: "small", "display": (temperatureData?.controlMode == "1") ? "" : "none" }}>Aktiv</div>
          <div style={{ fontSize: "small", "display": (temperatureData?.controlMode == "0") ? "none" : "" }}>{temperatureData?.switchValvePosition}</div>

          
        </Typography>

        <Dialog onClose={() => setHeatpumpInfoOpen(false)} open={heatpumpInfoOpen}>
          <List>
            <ListItem>
              <div style={{ fontSize: "small", "display": (temperatureData?.controlMode == "0") ? "" : "none" }}>Standby</div>
              <div style={{ fontSize: "small", "display": (temperatureData?.controlMode == "0") ? "none" : "" }}>{temperatureData?.switchValvePosition}</div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Vorlauf:</span> {formatNumber(temperatureData?.tempHeatFlow)}°C</div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Rücklauf:</span> {formatNumber(temperatureData?.tempHeatReflux)}°C</div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Kompressor:</span> {formatNumber(temperatureData?.compressorValue*100)}%</div>
            </ListItem>
            <ListItem>
              <div style={{ fontSize: "small"}}><span style={{color: "darkslategray"}}>Umwälzpumpe:</span> {formatNumber(temperatureData?.circPump*100)}%</div>
            </ListItem>
          </List>
        </Dialog>
      </Grid>

      <Grid item xs={4} style={{ "display": (liveData?.connected == true) ? "" : "none" }} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><EvStationIcon style={{ color: 'deepskyblue', fontSize: "xxx-large" }} /></div>
        <Typography component="p" variant="h5" style={{ textAlign: "center" }}>
          {formatNumber(chargePowerLive)}<span style={{ fontSize: "small" }}> {getWattLabel(chargePowerLive)}</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Wallbox
        </Typography>
      </Grid>
      
      <Grid item xs={4} style={{ "display": (liveData?.connected == true) ? "" : "none" }} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><SettingsSuggestIcon style={{ color: 'gray', fontSize: "xxx-large" }} /></div>
        <Typography component="p" variant="h5" style={{ textAlign: "center" }}>
          <select value={chargeMode} onChange={changeChargeMode}>
            <option value="minpv">Min+PV (1,3 kWh)</option>
            <option value="minpv2">Min+PV (2 kWh)</option>
            <option value="minpv3">Min+PV (3 kWh)</option>
            <option value="minpv35">Min+PV (3,5 kWh)</option>
            <option value="pv">Überschuss</option>
            <option value="now5">Schnell (5 kWh)</option>
            <option value="now7">Schnell (7 kWh)</option>
            <option value="now8">Schnell (8,4 kWh)</option>
            <option value="now9">Schnell (9 kWh)</option>
            <option value="now">Maximum (11 kWh)</option>
            <option value="off">Aus</option>
          </select>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Lademodus<br/>
          <span style={{ fontSize: "x-small", color: "darkslategray", display: (chargePowerLive > 0?"":"none") }}>Restzeit {remainingChargeTime}</span>
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ "display": (liveData?.connected == true) ? "" : "none" }}>
        <div className={"sectionTitle"} style={{ textAlign: "center" }}>
          <img src={Niro}></img>
          </div>
        <Typography component="div" variant="subtitle2" style={{ textAlign: "center", display: (liveData?.vehicleSoc > 0?"":"none") }}>
          {formatNumber(liveData?.vehicleSoc)}<span style={{ fontSize: "small" }}> %</span>
          <br/>
          {formatNumber(liveData?.vehicleRange)}<span style={{ fontSize: "small" }}> km</span>
        </Typography>
        <Typography component={"p"} variant='caption' color={"darkslategray"} style={{ textAlign: "center", display: (liveData?.vehicleSoc < 1?"":"none") }}>
          Verbunden
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography component="p" variant="caption" style={{ textAlign: "center", background: "#ececec" }}>
          Statistik
        </Typography>
      </Grid>

      <Grid item xs={12}>

        <Grid container>
          <Grid item xs={2}>
            <button className="datePicker-button" onClick={calendarBackward}>
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </button>
          </Grid>
          <Grid item xs={8}>
            <div style={{ textAlign: "center" }}>
              <button className="datePicker-button" onClick={handleClick}>
                <CalendarMonthIcon style={{ fontSize: "medium" }}></CalendarMonthIcon> {format(startDate, "dd.MM.yyyy")}
              </button>
              {isOpen && (
                <DatePicker dateFormat="dd.MM.yyyy" locale={"de"} selected={startDate} onChange={handleChange} minDate={new Date(2023, 5, 23)} maxDate={new Date()} inline />
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <button className="datePicker-button" onClick={calendarForward} disabled={startDate.getTime() >= today.getTime()}>
              {(startDate.getTime() < today.getTime())
                ? <ArrowForwardIosIcon></ArrowForwardIosIcon>
                : <span>&nbsp;</span>
              }
            </button>
          </Grid>
        </Grid>


      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><SpeedIcon style={{ color: 'orange', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(pvPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(pvPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Tagesproduktion
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><SavingsIcon style={{ color: 'violet', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(gridExportTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(gridExportTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Netzeinspeisung
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><BoltIcon style={{ color: 'orange', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(powerConsumptionTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(powerConsumptionTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Energieverbrauch (gesamt)
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}>
            <img src={mtec_wp} style={{height: "24px"}}></img>
        </div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(heatpumpPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(heatpumpPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Wärmepumpe
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><HomeIcon style={{ color: 'chocolate', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(homePowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(homePowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Hausverbrauch
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><ElectricCarIcon style={{ color: 'cadetblue', fontSize: "small" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center", fontSize: "small"  }}>
          {formatNumber(chargePowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(chargePowerTotal)}h</span>
        </Typography>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center", fontSize: "x-small" }}>
          {formatNumber(chargePowerTotalPVsupported)}<span style={{ fontSize: "xx-small" }}> {getWattLabel(chargePowerTotalPVsupported)}h PV</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Auto geladen
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><EnergySavingsLeafIcon style={{ color: 'green', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(selfConsumptionTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(selfConsumptionTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Eigenverbrauch
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><ElectricalServicesIcon style={{ color: 'gray', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(gridImportTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(gridImportTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Netzbezug
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><AllInclusiveIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber((100 / powerConsumptionTotal * selfConsumptionTotal))}<span style={{ fontSize: "small" }}> %</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Autarkie
        </Typography>
      </Grid>

      <Grid item xs={12} padding={2}>
        <ReactECharts
        style={{marginTop: "32px", height: "500px"}}
          option={getChartOptions()}
        />
        </Grid>
    </Grid>
  );
};

let dashboardDefinition = DashboardComponent;
if(process.env.REACT_APP_USE_AUTH == "true"){
  dashboardDefinition = withAuthenticationRequired(DashboardComponent, {
    onRedirecting: () => <Loading />,
  });
}
export default dashboardDefinition;