import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = ({ style }) => {
  const { logout } = useAuth0();

  return (
    <button className="btn btn-primary btn-block" style={style} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Abmelden
    </button>
  );
};

export default LogoutButton;