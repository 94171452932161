import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
const {REACT_APP_USE_AUTH, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID, REACT_APP_AUTH0_AUDIENCE} = process.env;

const root = ReactDOM.createRoot(document.getElementById('root'));
if(REACT_APP_USE_AUTH == "true"){
  let redirectUrl = window.location.origin;
  
  root.render(  
    <React.StrictMode>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
          redirect_uri: redirectUrl,
          audience: REACT_APP_AUTH0_AUDIENCE
        }}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
}else{
  root.render(<React.StrictMode><App /></React.StrictMode>);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
