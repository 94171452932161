import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import axios from "axios";

import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import HomeIcon from '@mui/icons-material/Home';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SavingsIcon from '@mui/icons-material/Savings';
import SpeedIcon from '@mui/icons-material/Speed';
import Grid from '@mui/material/Grid';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import EvStationIcon from '@mui/icons-material/EvStation';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import KitchenIcon from '@mui/icons-material/Kitchen';
import AirIcon from '@mui/icons-material/Air';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import HvacIcon from '@mui/icons-material/Hvac';
import StorageIcon from '@mui/icons-material/Storage';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import { format } from "date-fns";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from './Loading'

import ReactECharts from 'echarts-for-react';
import Niro from "./assets/niro.png"
import mtec_wp from "./assets/mtec-wp.png"

registerLocale('de', de)

function formatNumber(number, decimals) {
  if(typeof( decimals) == "undefined"){
    decimals = 0;
  }
  if (number > 1000) {
    return (number / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 });
  } else {
    return parseFloat(number).toLocaleString(undefined, { maximumFractionDigits: decimals });
  }
}

function getWattLabel(number) {
  if (number < 0)
    number = number * (-1);
  if (number > 1000) {
    return "kW";
  } else {
    return "W";
  }
}

const toUtcDate = function (requestDate) {
  return new Date(Date.UTC(requestDate.getUTCFullYear(), requestDate.getUTCMonth(),
    requestDate.getUTCDate(), requestDate.getUTCHours(),
    requestDate.getUTCMinutes(), requestDate.getUTCSeconds()));
}

const hostBaseUrl = process.env.REACT_APP_API_URL;
const today = new Date();
today.setHours(0, 0, 0, 0);

export const DashboardComponent = () => {


  const [pvPowerTotal, setPvPowerTotal] = useState(0);
  const [gridImportTotal, setGridImportTotal] = useState(0);
  const [gridExportTotal, setGridExportTotal] = useState(0);

  const [selfConsumptionTotal, setSelfConsumptionTotal] = useState(0);
  const [homePowerTotal, setHomePowerTotal] = useState(0);
  const [heatpumpPowerTotal, setheatpumpPowerTotal] = useState(0);
  const [powerConsumptionTotal, setPowerConsumptionTotal] = useState(0);
  const [chargePowerTotal, setChargePowerTotal] = useState(0);
  const [chargePowerTotalPVsupported, setChargePowerTotalPVsupported] = useState(0);
  const [ventilationPowerTotal, setVentilationPowerTotal] = useState(0);
  const [fridgePowerTotal, setFridgePowerTotal] = useState(0);
  const [freezerPowerTotal, setFreezerPowerTotal] = useState(0);
  const [washerPowerTotal, setWasherPowerTotal] = useState(0);
  const [dryerPowerTotal, setDryerPowerTotal] = useState(0);
  const [serverPowerTotal, setServerPowerTotal] = useState(0);




  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const queryApi = async (reset) => {
    
    console.log(startDate, window.queryDay);

    if (!window.queryDay) {
        window.queryDay = new Date();
    }

    let requestedDate = "month-"+format(window.queryDay, "yyyy-MM");

    // reset view
    if (typeof reset != "undefined") {
      setPvPowerTotal(0);
      setGridImportTotal(0);
      setGridExportTotal(0);
      setSelfConsumptionTotal(0);
      setPowerConsumptionTotal(0);
      setChargePowerTotal(0);
      setChargePowerTotalPVsupported(0);
      setHomePowerTotal(0);
      setheatpumpPowerTotal(0);
      setVentilationPowerTotal(0);
      setFridgePowerTotal(0);
      setFreezerPowerTotal(0);
      setWasherPowerTotal(0);
      setDryerPowerTotal(0);
      setServerPowerTotal(0);
    }

    let token = '';
    if(process.env.REACT_APP_USE_AUTH == "true"){
      token = await getAccessTokenSilently();
    }
    // frische daten
    //
    const result = await axios.get(`${hostBaseUrl}stats?cacheName=${requestedDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })

   
    setPvPowerTotal(result.data.pvPowerTotal);
    setGridImportTotal(result.data.gridImportTotal);
    setGridExportTotal(result.data.gridExportTotal);
    setSelfConsumptionTotal(result.data.selfConsumptionTotal);
    setPowerConsumptionTotal(result.data.powerConsumptionTotal);
    setChargePowerTotal(result.data.chargePowerTotal);
    setChargePowerTotalPVsupported(result.data.chargePowerPVsupportedTotal);

    setHomePowerTotal(result.data.homePowerTotal);
    setheatpumpPowerTotal(result.data.heatpumpPowerTotal);

    setVentilationPowerTotal(result.data.ventilationPowerTotal);
    setFridgePowerTotal(result.data.fridgePowerTotal);
    setFreezerPowerTotal(result.data.freezerPowerTotal);
    setWasherPowerTotal(result.data.washerPowerTotal);
    setDryerPowerTotal(result.data.dryerPowerTotal);
    setServerPowerTotal(result.data.serverPowerTotal);
  };

  const switchDay = (day) => {
    setStartDate(day);
    window.queryDay = toUtcDate(day);
  
    //reset/load
    queryApi(true);
  };
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    switchDay(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const calendarBackward = (e) => {
    let currentDate = new Date(window.queryDay);
    let newDate = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, 1);
    switchDay(newDate);
  };

  const calendarForward = (e) => {
    let currentDate = new Date(window.queryDay);
    let newDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1);
    switchDay(newDate);
  };


  const globalDashboardClick = (event) => {
    if(event.target.tagName.toLocaleLowerCase() == "canvas"){
      return;
    }
   
    let tooltips = document.getElementsByClassName("tooltip");
    if(tooltips.length > 0){
      tooltips[0].style.display = "none";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await queryApi();
    };


    const id = setInterval(() => {
      fetchData();
    }, 5000);

    fetchData();

    return () => { clearInterval(id); };
  }, [])



  return (
    <Grid container spacing={0} padding={0} onClick={globalDashboardClick}>
      <Grid item xs={12}>
        <Typography component="p" variant="caption" style={{ textAlign: "center", background: "#ececec" }}>
          Monate
        </Typography>
      </Grid>

      <Grid item xs={12}>

        <Grid container>
          <Grid item xs={2}>
            <button className="datePicker-button" onClick={calendarBackward} disabled={startDate.getTime() <= new Date(2023, 5, 23) }>
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </button>
          </Grid>
          <Grid item xs={8}>
            <div style={{ textAlign: "center" }}>
              <button className="datePicker-button" onClick={handleClick}>
                <CalendarMonthIcon style={{ fontSize: "medium" }}></CalendarMonthIcon> <span style={{fontSize: "medium"}}>{format(startDate, "MMMM yyyy", {locale: de})}</span>
              </button>
              {isOpen && (
                <DatePicker locale={"de"} selected={startDate} onChange={handleChange} minDate={new Date(2023, 5, 23)} maxDate={new Date()} inline showMonthYearPicker />
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            <button className="datePicker-button" onClick={calendarForward} disabled={startDate.getMonth() == today.getMonth()}>
              {(startDate.getMonth() != today.getMonth())
                ? <ArrowForwardIosIcon></ArrowForwardIosIcon>
                : <span>&nbsp;</span>
              }
            </button>
          </Grid>
        </Grid>


      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><SpeedIcon style={{ color: 'orange', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(pvPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(pvPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Produktion
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><SavingsIcon style={{ color: 'violet', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(gridExportTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(gridExportTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Netzeinspeisung
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><BoltIcon style={{ color: 'orange', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(powerConsumptionTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(powerConsumptionTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Energieverbrauch (gesamt)
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}>
            <img src={mtec_wp} style={{height: "24px"}}></img>
        </div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(heatpumpPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(heatpumpPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Wärmepumpe
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><HomeIcon style={{ color: 'chocolate', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(homePowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(homePowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Hausverbrauch
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><ElectricCarIcon style={{ color: 'cadetblue', fontSize: "small" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center", fontSize: "small"  }}>
          {formatNumber(chargePowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(chargePowerTotal)}h</span>
        </Typography>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center", fontSize: "x-small" }}>
          {formatNumber(chargePowerTotalPVsupported)}<span style={{ fontSize: "xx-small" }}> {getWattLabel(chargePowerTotalPVsupported)}h PV</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Auto geladen
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><EnergySavingsLeafIcon style={{ color: 'green', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(selfConsumptionTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(selfConsumptionTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Eigenverbrauch
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><ElectricalServicesIcon style={{ color: 'gray', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(gridImportTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(gridImportTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Netzbezug
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><AllInclusiveIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber((100 / powerConsumptionTotal * selfConsumptionTotal))}<span style={{ fontSize: "small" }}> %</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Autarkie
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography component="p" variant="caption" style={{ textAlign: "center", background: "#ececec", marginTop:"16px" }}>
          Hausverbrauch details
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><AirIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(ventilationPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(ventilationPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Wohnraumlüftung
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><KitchenIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(fridgePowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(fridgePowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Kühlschrank
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><AcUnitIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(freezerPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(freezerPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Tiefkühler
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><LocalLaundryServiceIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(washerPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(washerPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Waschmaschine
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><HvacIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(dryerPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(dryerPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Trockner
        </Typography>
      </Grid>

      <Grid item xs={4} >
        <div className={"sectionTitle"} style={{ textAlign: "center" }}><StorageIcon style={{ color: 'silver', fontSize: "x-large" }} /></div>
        <Typography component="p" variant="subtitle1" style={{ textAlign: "center" }}>
          {formatNumber(serverPowerTotal)}<span style={{ fontSize: "small" }}> {getWattLabel(serverPowerTotal)}h</span>
        </Typography>
        <Typography component={"p"} variant='caption' style={{ textAlign: "center" }} color={"darkslategray"}>
          Nas/Server
        </Typography>
      </Grid>
    </Grid>
  );
};

let dashboardDefinition = DashboardComponent;
if(process.env.REACT_APP_USE_AUTH == "true"){
  dashboardDefinition = withAuthenticationRequired(DashboardComponent, {
    onRedirecting: () => <Loading />,
  });
}
export default dashboardDefinition;