import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard'
import HistoryDashboard from './MonthDashboard'
import { useAuth0 } from "@auth0/auth0-react";
import Loading from './Loading';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import Profile from './Profile';

import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Link
} from 'react-router-dom';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
          width: 0,
        },
      }),
    },
  }),
);


function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading && process.env.REACT_APP_USE_AUTH == "true") {
    return <Loading />;
  }

  let UserAuthContainer = () => <Container style={{ paddingTop: "16px" }}></Container>;

  if( process.env.REACT_APP_USE_AUTH == "true" ){
    UserAuthContainer = () => <Container style={{ paddingTop: "16px" }}>
      <LoginButton style={{ display: (isAuthenticated == true) ? "none" : "" }}></LoginButton>
      <LogoutButton style={{ display: (isAuthenticated == true) ? "" : "none" }}></LogoutButton>
      <Profile></Profile>  
    </Container>;
  }  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <BrowserRouter>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              z10-solar power monitor
            </Typography>
            <IconButton color="inherit">
              {/*               <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Box>
            <UserAuthContainer />
          </Box>
          <Divider />
          <Box>
            <Button sx={{}} size="large" onClick={toggleDrawer} variant="outlined" component={Link} to="/" fullWidth={true}>Start   
            </Button>
          </Box>
          <Box>
            <Button sx={{}} size="large" onClick={toggleDrawer} variant="outlined" component={Link} to="/months" fullWidth={true}>Monate   
            </Button>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
            <Grid container spacing={1}>
            
              <Grid item xs={12}>
              
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
                  
                    <Routes>
                      <Route path="/" element={
                        <Dashboard></Dashboard>
                      }>
                      </Route>
                      <Route path="/months" element={
                        <HistoryDashboard></HistoryDashboard>
                      }></Route>
                    </Routes>
                  
                </Paper>
                
              </Grid>
            </Grid>

          </Container>
        </Box>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;
